/* SplashScreen.css */
.splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #282c34;
}

.splash-logo {
    width: 200px;
    height: 200px;
    animation: float 3s ease-in-out;
}

@keyframes float {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    50% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0;
    }
}