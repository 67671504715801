/* App.css */
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.output-image {
    object-fit: fill;
}

.small-button {
    font-size: 0.7rem;
    /* Reduce font size by 30% */
    padding: 0.5rem 1rem;
    /* Adjust padding */
}

body {
    margin: 0;
    padding-bottom: env(safe-area-inset-bottom);
}

.min-h-screen {
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
}

@keyframes blink {
    0% {
        border-color: #7a7a7a;
    }

    50% {
        border-color: #575757;
        ;
    }

    100% {
        border-color: #2a2a2a;
    }
}

.border-blink {
    animation: blink 4s ease-in-out infinite;
}